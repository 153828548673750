<template>
	<SectionCard>
		<template #title>
			Other Config
		</template>
		<template #body>
			<Dropdown
				:value="preferenceWidget.optionAppearance || CHECKBOX_APPEARANCE"
				:items="optionAppearanceOptions"
				label="Option appearance *"
				class="cassie-input-width-md"
				rules="required"
				:disabled="!userFullPermissions"
				@change="updatePreferenceWidget('optionAppearance', $event)"
			/>
			<v-expansion-panels v-if="preferenceWidget.optionAppearance && preferenceWidget.optionAppearance !== CHECKBOX_APPEARANCE">
				<v-expansion-panel>
					<v-expansion-panel-header>
						Show Advanced Options
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-row dense>
							<v-col cols="4">
								<TextField
									:value="preferenceWidget.optInLabel"
									label="Opt In Label"
									:disabled="!userFullPermissions"
									@input="updatePreferenceWidget('optInLabel', $event)"
								/>
							</v-col>
							<v-col cols="4">
								<TextField
									:value="preferenceWidget.optOutLabel"
									label="Opt Out Label"
									:disabled="!userFullPermissions"
									@input="updatePreferenceWidget('optOutLabel', $event)"
								/>
							</v-col>
							<v-col
								v-if="preferenceWidget.optionAppearance === RADIO_WITH_NO_CHANGE_APPEARANCE"
								cols="4"
							>
								<TextField
									:value="preferenceWidget.noChangeLabel"
									label="No Change Label"
									:disabled="!userFullPermissions"
									@input="updatePreferenceWidget('noChangeLabel', $event)"
								/>
							</v-col>
							<v-col cols="4">
								<Dropdown
									:value="preferenceWidget.labelLocation"
									label="Label Location"
									:items="labelLocationOptions"
									:disabled="!userFullPermissions"
									@input="updatePreferenceWidget('labelLocation', $event)"
								/>
							</v-col>
							<v-col cols="4">
								<Dropdown
									:value="preferenceWidget.optionOrder"
									label="Option Label Order"
									:items="optionOrderItems"
									:disabled="!userFullPermissions"
									@input="updatePreferenceWidget('optionOrder', $event)"
								/>
							</v-col>
							<v-col cols="4">
								<Dropdown
									:value="preferenceWidget.defaultOption"
									label="Option Selected by Default"
									:items="defaultOptions"
									:disabled="!userFullPermissions"
									@input="updatePreferenceWidget('defaultOption', $event)"
								/>
							</v-col>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</template>
	</SectionCard>
</template>

<script>

import SectionCard from '../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import {
	CHECKBOX_APPEARANCE,
	OPTION_NO_CHANGE,
	RADIO_APPEARANCE,
	RADIO_WITH_NO_CHANGE_APPEARANCE,
	optionOrderWithNoChange,
	labelLocationOptions
} from './widget-configuration.js'

export default {
	components: { TextField, Dropdown, SectionCard },
	props: {
		preferenceWidget: Object,
		updatePreferenceWidget: Function,
		lookupData: Object,
		userFullPermissions: Boolean,
		editMode: Boolean
	},
	data () {
		return {
			CHECKBOX_APPEARANCE,
			RADIO_APPEARANCE,
			RADIO_WITH_NO_CHANGE_APPEARANCE,
			optionOrderWithNoChange,
			labelLocationOptions
		}
	},
	computed: {
		optionOrderItems () {
			const optInOptOut = 2
			if (this.preferenceWidget.optionAppearance === optInOptOut) {
				return [
					{
						text: 'Opt Out | Opt In',
						value: false
					},
					{
						text: 'Opt In | Opt Out',
						value: true
					}
				]
			} else {
				return optionOrderWithNoChange
			}
		},
		defaultOptions () {
			if (this.lookupData.optionSelectedByDefaults) {
				const defaultSelectedOptions = this.lookupData.optionSelectedByDefaults.map(({ id, description }) => ({
					value: id,
					text: description
				}))
				if (this.preferenceWidget.optionAppearance === RADIO_WITH_NO_CHANGE_APPEARANCE) {
					return defaultSelectedOptions
				} 	else {
					return defaultSelectedOptions.filter(({ value }) => value !== OPTION_NO_CHANGE)
				}
			} else {
				return []
			}
		},
		optionAppearanceOptions () {
			if (this.lookupData.optionAppearances) {
				return this.lookupData.optionAppearances.map(({ description, id }) => ({
					value: id,
					text: description
				}))
			} else {
				return []
			}
		}
	},
	created () {
		if (!this.editMode && !this.preferenceWidget.optionAppearance) {
			this.updatePreferenceWidget('optionAppearance', CHECKBOX_APPEARANCE)
		}
	}
}
</script>
