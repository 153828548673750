<template>
	<SectionCard>
		<template #title>
			Preferences
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<Dropdown
						v-model="selectedChannelId"
						:items="channelOptions"
						custom-sort
						:disabled="!userFullPermissions"
						item-disabled="disabled"
						:label="'Channel *' | useLabels"
					/>
				</v-col>
				<v-col cols="6">
					<div class="d-flex cassie-horizontal-md">
						<Dropdown
							v-model="selectedStatementGroupId"
							:disabled="!selectedChannelId || !userFullPermissions"
							:items="selectedChannelStatementGroups"
							:label="'Statement Group *' | useLabels"
							:no-data-text="'No Statement Groups are available' | useLabels"
							style="flex: 1;"
						/>
						<PrimaryActionButton
							:disabled="!selectedChannelId || !selectedStatementGroupId || !userFullPermissions"
							@click="addStatementGroup"
						>
							Add
						</PrimaryActionButton>
					</div>
				</v-col>
			</v-row>
			<Toggle
				v-if="isDoubleOptInChannel"
				:value="enforceDoubleOptIn[selectedChannelId] || false"
				label="Enforce Double Opt In?"
				tooltip-text="Enabling this functionality will require a double opt-in confirmation via email."
				@update:value="handleToggle"
			/>

			<DataTable
				:headers="tableHeaders"
				:items="translatedStatementGroups"
				:disable-sort="true"
			>
				<template #item.statementGroupName="{ item }">
					{{ getTruncatedText(item.statementGroupName, 50) }}
				</template>
				<template #item.enforceDoubleOptIn="{ item }">
					<v-icon small>
						{{ getIsDoubleOptIn(item) ? 'mdi-check' : 'mdi-close' }}
					</v-icon>
				</template>
				<template #item.action="{ index, item }">
					<IconButton
						v-if="index !== 0"
						small-icon
						small
						:disabled="!userFullPermissions"
						@click="moveStatementGroupUp(index)"
					>
						mdi-arrow-up
					</IconButton>
					<IconButton
						v-if="index !== translatedStatementGroups.length - 1"
						small-icon
						small
						:disabled="!userFullPermissions"
						@click="moveStatementGroupDown(index)"
					>
						mdi-arrow-down
					</IconButton>
					<IconButton
						small-icon
						small
						:disabled="!userFullPermissions || linkedExtendedPreference(item)"
						:tooltip-text="'Remove statement' | useLabels"
						@click="removeStatementGroup(index)"
					>
						mdi-trash-can
					</IconButton>
				</template>
			</DataTable>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import sortParentAndChildChannelsMixin from '../../../../../shared/mixins/sort-parent-and-child-channels.js'
import { clientMultiBranded, UNIVERSAL_BRAND } from '../../../../../shared/state/brands.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { getTruncatedText } from '../../../../../shared/utils/utils.js'

export default {
	components: { IconButton, DataTable, PrimaryActionButton, Dropdown, SectionCard, Toggle },
	mixins: [sortParentAndChildChannelsMixin],
	props: {
		preferenceWidget: Object,
		updatePreferenceWidget: Function,
		channels: {
			type: Array,
			default: () => []
		},
		userFullPermissions: Boolean
	},
	setup () {
		return {
			clientMultiBranded,
			UNIVERSAL_BRAND,
			getTruncatedText
		}
	},
	data () {
		return {
			selectedChannelId: null,
			selectedStatementGroupId: null,
			selectedLanguageId: 0,
			enforceDoubleOptIn: {}
		}
	},
	computed: {
		selectedChannel () {
			return this.channels.find(({ channelId }) => channelId === this.selectedChannelId)
		},
		tableHeaders () {
			return [
				{ value: 'statementGroupID', text: 'ID' },
				{ value: 'statementGroupName', text: useLabels('Statement Group Name') },
				{ value: 'channelName', text: useLabels('Channel') },
				{ value: 'brandName', text: useLabels('Brand') },
				{ value: 'enforceDoubleOptIn', text: 'Double Opt In?' },
				{ value: 'action', text: 'Action' }
			]
		},
		selectedChannelStatementGroups () {
			const selectedStatementGroups = this.statementGroups.map(statementGroup => {
				return statementGroup.statementGroupID
			})
			const liveStatements = this.channels?.map(channel => { return channel.statements }).flat().map(
				statement => { return { ...statement, liveStatements: statement.liveStatements.filter(liveStatement => liveStatement.languageId === 0) } })
				.filter(filteredstatement => filteredstatement.liveStatements.length > 0).flat().map(statement => statement.statementID).flat()
			const statementGroupsToReturn = this.selectedChannel?.statementGroups.map(
				statementGroup => { return { ...statementGroup, statements: statementGroup.statements.filter(s => s.isLive === true && liveStatements.includes(s.statementId)) } })
				.filter(({ statementGroupID, statements }) => !selectedStatementGroups.includes(statementGroupID) && statements.length > 0) || []
			return statementGroupsToReturn.map(statementGroup => ({
				...statementGroup,
				text: getTruncatedText(statementGroup.statementGroupName, 50),
				value: statementGroup.statementGroupID
			}))
		},
		statementGroups () {
			return this.preferenceWidget.statementGroups || []
		},
		channelOptions () {
			const channelOptions = this.sortParentAndChildChannels(this.channels)
				.filter(channel => channel.channelType.doubleOptInRelatedChannelID === 0 || channel.channelType.doubleOptInRelatedChannelID === null)
				.map(({ channelId, channelName, channelTranslations, brandName, brandID, statementGroups }) => {
					const hasStatementGroups = statementGroups.length > 0
					const translatedChannel = channelTranslations
						?.find(({ languageID }) => languageID === this.selectedLanguageId)
					if (clientMultiBranded.value) {
						return {
							value: channelId,
							text: `${translatedChannel?.translatedChannelName ?? channelName} - ${brandName}
							${!hasStatementGroups ? '(No Statement Groups configured)' : ''}`,
							brandId: brandID,
							disabled: !hasStatementGroups
						}
					} else {
						return {
							value: channelId,
							text: `${translatedChannel?.translatedChannelName ?? channelName} ${!hasStatementGroups ? '(No Statement Groups configured)' : ''}`,
							brandId: brandID,
							disabled: !hasStatementGroups
						}
					}
				})

			if (this.preferenceWidget.brandId === UNIVERSAL_BRAND) {
				return channelOptions.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				return channelOptions.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.preferenceWidget.brandId)
			}
		},
		translatedStatementGroups () {
			return this.statementGroups.map(({ statementGroupID, statementGroupName, channelId, channelName, brandName, enforceDoubleOptIn }) => {
				const channelTranslations = this.channels.find(c => c.channelId === channelId)?.channelTranslations ?? []
				const translatedChannel = channelTranslations?.find(({ languageID }) => languageID === this.selectedLanguageId)
				return {
					statementGroupID: statementGroupID,
					statementGroupName: statementGroupName,
					channelId: channelId,
					channelName: translatedChannel?.translatedChannelName ?? channelName,
					brandName: brandName,
					enforceDoubleOptIn: enforceDoubleOptIn
				}
			})
		},
		isDoubleOptInChannel () {
			const channel = this.channels.find(channel => channel.channelId === this.selectedChannelId)
			return channel?.channelType?.doubleOptInEligible === true
		}
	},
	methods: {
		updatePreferenceWidgetStatementGroups (statementGroups) {
			this.updatePreferenceWidget('statementGroups', statementGroups)
		},
		move (array, from, to) {
			const arrayCopy = JSON.parse(JSON.stringify(array))
			arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0])
			return arrayCopy
		},
		linkedExtendedPreference (statementGroup) {
			return this.preferenceWidget.extendedPreferences?.filter(({ statementGroupId }) => statementGroupId === statementGroup.statementGroupID).length >= 1
		},
		removeStatementGroup (index) {
			const statementGroups = [...this.statementGroups]
			this.$delete(statementGroups, index)
			this.updatePreferenceWidgetStatementGroups(statementGroups)
		},
		moveStatementGroupDown (index) {
			this.updatePreferenceWidgetStatementGroups(this.move(this.statementGroups, index, index + 1))
		},
		moveStatementGroupUp (index) {
			this.updatePreferenceWidgetStatementGroups(this.move(this.statementGroups, index, index - 1))
		},
		addStatementGroup () {
			const displayOrder = this.statementGroups.length
			const statementGroup = this.selectedChannelStatementGroups.find(({ statementGroupID }) => statementGroupID === this.selectedStatementGroupId)
			const enforceDoubleOptInValue = this.enforceDoubleOptIn[this.selectedChannelId] || false
			const selectedChannel = this.channels.find(channel => channel.channelId === this.selectedChannelId)
			let channelIdToAdd
			let channelNameToAdd

			if (enforceDoubleOptInValue) {
				const relatedChannel = this.channels.find(channel => channel.channelType.doubleOptInRelatedChannelID === selectedChannel?.channelId)
				channelIdToAdd = relatedChannel?.channelId || this.selectedChannelId
				channelNameToAdd = relatedChannel?.channelName || selectedChannel?.channelName
			} else {
				channelIdToAdd = this.selectedChannelId
				channelNameToAdd = selectedChannel?.channelName
			}

			this.updatePreferenceWidgetStatementGroups([
				...this.statementGroups,
				{
					statementGroupID: statementGroup.statementGroupID,
					statementGroupName: statementGroup.statementGroupName,
					channelId: channelIdToAdd,
					channelName: channelNameToAdd,
					brandName: this.selectedChannel.brandName,
					displayOrder: displayOrder,
					statements: statementGroup.statements,
					enforceDoubleOptIn: enforceDoubleOptInValue
				}
			])
			this.selectedChannelId = null
			this.selectedStatementGroupId = null
		},
		handleToggle (value) {
			if (this.selectedChannelId !== null) {
				this.$set(this.enforceDoubleOptIn, this.selectedChannelId, value)
			}
		},
		getIsDoubleOptIn (statementGroup) {
			return statementGroup.enforceDoubleOptIn
		}
	}
}
</script>
