var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Preferences ")]},proxy:true},{key:"body",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('Dropdown',{attrs:{"items":_vm.channelOptions,"custom-sort":"","disabled":!_vm.userFullPermissions,"item-disabled":"disabled","label":_vm._f("useLabels")('Channel *')},model:{value:(_vm.selectedChannelId),callback:function ($$v) {_vm.selectedChannelId=$$v},expression:"selectedChannelId"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex cassie-horizontal-md"},[_c('Dropdown',{staticStyle:{"flex":"1"},attrs:{"disabled":!_vm.selectedChannelId || !_vm.userFullPermissions,"items":_vm.selectedChannelStatementGroups,"label":_vm._f("useLabels")('Statement Group *'),"no-data-text":_vm._f("useLabels")('No Statement Groups are available')},model:{value:(_vm.selectedStatementGroupId),callback:function ($$v) {_vm.selectedStatementGroupId=$$v},expression:"selectedStatementGroupId"}}),_c('PrimaryActionButton',{attrs:{"disabled":!_vm.selectedChannelId || !_vm.selectedStatementGroupId || !_vm.userFullPermissions},on:{"click":_vm.addStatementGroup}},[_vm._v(" Add ")])],1)])],1),(_vm.isDoubleOptInChannel)?_c('Toggle',{attrs:{"value":_vm.enforceDoubleOptIn[_vm.selectedChannelId] || false,"label":"Enforce Double Opt In?","tooltip-text":"Enabling this functionality will require a double opt-in confirmation via email."},on:{"update:value":_vm.handleToggle}}):_vm._e(),_c('DataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.translatedStatementGroups,"disable-sort":true},scopedSlots:_vm._u([{key:"item.statementGroupName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTruncatedText(item.statementGroupName, 50))+" ")]}},{key:"item.enforceDoubleOptIn",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getIsDoubleOptIn(item) ? 'mdi-check' : 'mdi-close')+" ")])]}},{key:"item.action",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(index !== 0)?_c('IconButton',{attrs:{"small-icon":"","small":"","disabled":!_vm.userFullPermissions},on:{"click":function($event){return _vm.moveStatementGroupUp(index)}}},[_vm._v(" mdi-arrow-up ")]):_vm._e(),(index !== _vm.translatedStatementGroups.length - 1)?_c('IconButton',{attrs:{"small-icon":"","small":"","disabled":!_vm.userFullPermissions},on:{"click":function($event){return _vm.moveStatementGroupDown(index)}}},[_vm._v(" mdi-arrow-down ")]):_vm._e(),_c('IconButton',{attrs:{"small-icon":"","small":"","disabled":!_vm.userFullPermissions || _vm.linkedExtendedPreference(item),"tooltip-text":_vm._f("useLabels")('Remove statement')},on:{"click":function($event){return _vm.removeStatementGroup(index)}}},[_vm._v(" mdi-trash-can ")])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }