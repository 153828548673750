<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal
			width="1000px"
		>
			<template #modal-title>
				{{ modalTitle }}
				<v-spacer />
				<IconButton
					@click="close()"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<Dropdown
						v-model="extendedPreference.keyId"
						label="Extended Preference *"
						:items="extendedPreferencesItemsMapped"
						:disabled="isEdit"
						rules="required"
						@change="getAvailableDropdownOptions"
					/>
					<Dropdown
						v-model="extendedPreference.fieldTypeId"
						label="Fields Type in User Interface *"
						rules="required"
						:items="fieldTypeItems"
					/>
					<TextField
						v-model="extendedPreference.keyLabel"
						:label="fieldLabelLabel"
						class="cassie-input-width-xl"
						:rules="{required: !extendedPreference.hideFromUi, max: 255}"
					/>
					<v-divider />
					<div class="cassie-horizontal-md d-flex flex-row">
						<v-switch
							v-model="extendedPreference.hideFromUi"
							label="Hide from UI*"
							class="cassie-input-width-sm"
							@change="changeHideFromUi"
						/>
						<v-switch
							v-model="extendedPreference.isEditable"
							label="Is Editable*"
							class="cassie-input-width-sm"
							@change="changeIsEditable"
						/>
						<v-switch
							v-if="extendedPreference.fieldTypeId !== EXTENDED_PREFERENCES_FIELD_TYPES.MULTI_SELECT_DROPDOWN_FIELD"
							v-model="extendedPreference.hasDefaultValue"
							label="Has Default Value*"
							class="cassie-input-width-sm"
							@change="changeDefaultValue"
						/>
					</div>
					<v-divider />
					<div
						v-if="extendedPreference.fieldTypeId"
						class="cassie-vertical-md"
					>
						<div
							v-if="extendedPreference.fieldTypeId !== EXTENDED_PREFERENCES_FIELD_TYPES.FREE_TEXT_FIELD"
							class="cassie-vertical-md"
						>
							<v-simple-table
								v-if="extendedPreference.extendedPreferenceAvailableValues"
								dense
								light
							>
								<template #default>
									<thead>
										<tr>
											<th class="text-left">
												Available Dropdown Menu Items
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item) in extendedPreference.extendedPreferenceAvailableValues"
											:key="item.extendedPreferenceKeyAvailableValueId"
										>
											<td>
												{{ item.extendedPreferenceKeyAvailableValue }}
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							<v-divider />
							<Dropdown
								v-if="extendedPreference.fieldTypeId === EXTENDED_PREFERENCES_FIELD_TYPES.DROPDOWN_FIELD && extendedPreference.hasDefaultValue"
								v-model="extendedPreference.defaultSingleSelectValue"
								label="Default Value*"
								:items="dropdownDefaultValueItems"
								rules="required"
							/>
						</div>
						<TextField
							v-if="extendedPreference.fieldTypeId === EXTENDED_PREFERENCES_FIELD_TYPES.FREE_TEXT_FIELD && extendedPreference.hasDefaultValue"
							v-model="extendedPreference.defaultFreeTextValue"
							class="cassie-input-width-xl"
							label="Default Value*"
							rules="required"
						/>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton
					@click="close"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					@click="handleSubmit(addExtendedPreference)"
				>
					{{ isEdit ? 'Update' : 'Add' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>
<script>
import Modal from '../../../../shared/components/modal.vue'
import Dropdown from '../../../../shared/components/dropdown.vue'
import IconButton from '../../../../shared/components/icon-button.vue'
import ValidationForm from '../../../../shared/components/validation-form.vue'
import PrimaryActionButton from '../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../shared/components/secondary-action-button.vue'
import TextField from '../../../../shared/components/text-field.vue'
import { EXTENDED_PREFERENCES_FIELD_TYPES } from '../../../../shared/enums/extended-preferences.js'
import { getAssignedLanguages } from '../../../../shared/utils/api/languages.js'
import { getExtendedPreferences } from '../../../../shared/utils/api/extended-preferences.js'

export default {
	components: { Modal, Dropdown, IconButton, TextField, PrimaryActionButton, SecondaryActionButton, ValidationForm },
	props: {
		extendedPreferenceToEdit: Object,
		extendedPreferences: Array,
		channel: Object,
		statementGroup: Object,
		isPreferenceWidget: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			EXTENDED_PREFERENCES_FIELD_TYPES,
			selectedFieldType: null,
			assignedLanguages: [],
			selectedLanguage: null,
			extendedPreferencesItems: [],
			extendedPreferenceFieldTypes: [],
			dropdownValues: [],
			disableHideFromUi: false,
			extendedPreference: JSON.parse(JSON.stringify(this.extendedPreferenceToEdit || {
				keyLabel: null,
				statementId: !this.isPreferenceWidget ? this.channel.statementId : null,
				statementGroupId: this.isPreferenceWidget ? this.statementGroup?.statementGroupID : null,
				statementGroupName: this.isPreferenceWidget ? this.statementGroup?.statementGroupName : null,
				channelId: this.isPreferenceWidget ? this.statementGroup?.channelId : this.channel.channelId,
				channelName: this.isPreferenceWidget ? this.statementGroup?.channelName : this.channel.channelName,
				fieldTypeId: null,
				keyId: null,
				hideFromUi: false,
				isEditable: true,
				hasDefaultValue: null,
				defaultFreeTextValue: null,
				defaultSingleSelectValue: null,
				extendedPreferenceAvailableValues: null
			}))
		}
	},
	computed: {
		isEdit () {
			return Boolean(this.extendedPreferenceToEdit)
		},
		modalTitle () {
			return `${this.isEdit ? 'Edit' : 'Create'} Extended Preference`
		},
		fieldLabelLabel () {
			return `${this.extendedPreference.hideFromUi ? 'Field Label' : 'Field Label *'}`
		},
		fieldTypeItems () {
			if (this.extendedPreference.extendedPreferenceAvailableValues != null && this.extendedPreference.extendedPreferenceAvailableValues.length > 0) {
				const mappedFieldTypes = this.extendedPreferenceFieldTypes.map(fieldType => ({
					value: fieldType.fieldTypeId,
					text: fieldType.fieldTypeName
				}))

				if (this.extendedPreference.hasDefaultValue) return mappedFieldTypes.filter(({ value }) => value !== EXTENDED_PREFERENCES_FIELD_TYPES.MULTI_SELECT_DROPDOWN_FIELD)
				else return mappedFieldTypes
			}
			return [{
				value: 1,
				text: 'Free text'
			}]
		},
		extendedPreferencesItemsMapped () {
			let existingExtPrefsWithSelectedChannelId = []
			if (this.channel) {
				existingExtPrefsWithSelectedChannelId = this.extendedPreferences.filter(
					({ channelId }) => channelId === this.channel.channelId).map(({ keyId }) => keyId
				)
			} else {
				existingExtPrefsWithSelectedChannelId = this.extendedPreferences.filter(
					({ channelId, keyId }) => channelId === this.extendedPreference.channelId && keyId !== this.extendedPreference.keyId).map(({ keyId }) => keyId
				)
			}
			// This doesnt alow you to edit as it removoed the ext pref
			const filteredExtendedPreferenceItems = this.extendedPreferencesItems.filter(
				({ extendedPreferenceId }) => !existingExtPrefsWithSelectedChannelId.includes(extendedPreferenceId)
			)
			return filteredExtendedPreferenceItems.map(extendedPref => ({
				value: extendedPref.extendedPreferenceId,
				text: extendedPref.name
			}))
		},
		dropdownDefaultValueItems () {
			return this.extendedPreference.extendedPreferenceAvailableValues.map(dropdownValue => ({
				value: dropdownValue.extendedPreferenceKeyAvailableValueId,
				text: dropdownValue.extendedPreferenceKeyAvailableValue
			}))
		},
		defaultValueProvided () {
			const defaultTextValue = this.extendedPreference.defaultFreeTextValue !== null
			const defaultSingleSelectValue = this.extendedPreference.defaultSingleSelectValue !== null
			return Boolean(defaultTextValue || defaultSingleSelectValue)
		},
		assignedLanguagesItems () {
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			}))
		}
	},
	async created () {
		const { data: assignedLanguages } = await getAssignedLanguages()
		const { extendedPreferences, lookupData } = await getExtendedPreferences()
		this.assignedLanguages = assignedLanguages
		this.extendedPreferencesItems = extendedPreferences
		this.extendedPreferenceFieldTypes = lookupData.extendedPreferenceFieldTypes
		if (this.isEdit && this.defaultValueProvided) {
			this.extendedPreference.hasDefaultValue = true
			this.getAvailableDropdownOptions()
		}
	},
	methods: {
		close () {
			this.$emit('close')
		},
		changeIsEditable (value) {
			this.extendedPreference.hideFromUi = !value
		},
		changeHideFromUi (value) {
			this.extendedPreference.isEditable = !value
			if (value === true) this.extendedPreference.hasDefaultValue = true
		},
		changeDefaultValue (value) {
			if (value === false) {
				this.extendedPreference.isEditable = true
				this.extendedPreference.hideFromUi = false
				this.extendedPreference.hasDefaultValue = false
				this.extendedPreference.defaultFreeTextValue = null
				this.extendedPreference.defaultSingleSelectValue = null
			}
		},
		getAvailableDropdownOptions () {
			const extendedPreference = this.extendedPreferencesItems.find(({ extendedPreferenceId }) => extendedPreferenceId === this.extendedPreference.keyId)
			this.extendedPreference.extendedPreferenceAvailableValues = extendedPreference.dropdownValues.map(dropdownValue => ({
				extendedPreferenceKeyAvailableValueId: dropdownValue.valueId,
				extendedPreferenceKeyAvailableValue: dropdownValue.value
			}))
		},
		changeSelectedExtendedPreference () {
			this.extendedPreference = this.extendedPreferencesItems.find(({ extendedPreferenceId }) => extendedPreferenceId === this.extendedPreference.keyId)
		},
		updateExtendedPreferences (extendedPreferences) {
			this.$emit('update:extendedPreferences', extendedPreferences)
		},
		addExtendedPreference () {
			if (!this.isEdit) {
				this.updateExtendedPreferences([
					...(this.extendedPreferences),
					{
						...this.extendedPreference,
						fieldTypeName: this.fieldTypeItems.find(({ value }) => value === this.extendedPreference.fieldTypeId).text,
						extendedPreferenceName: this.extendedPreferencesItemsMapped.find(({ value }) => value === this.extendedPreference.keyId).text
					}
				])
			} else {
				const existingExtendedPreferences = this.extendedPreferences
				const index = existingExtendedPreferences.findIndex(({ keyId, channelId }) => keyId === this.extendedPreference.keyId && channelId === this.extendedPreference.channelId)
				const editedExtendedPreference = this.extendedPreference
				editedExtendedPreference.fieldTypeName = this.fieldTypeItems.find(({ value }) => value === this.extendedPreference.fieldTypeId).text
				editedExtendedPreference.extendedPreferenceName = this.extendedPreferencesItemsMapped.find(({ value }) => value === this.extendedPreference.keyId).text

				this.$set(existingExtendedPreferences, index, this.extendedPreference)
				this.updateExtendedPreferences(existingExtendedPreferences)
			}
			this.close()
			this.$emit('extended-prefs-changed')
		}
	}

}
</script>
