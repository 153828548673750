import { format } from 'date-fns'
import { DATE_FORMAT } from '../../utils/date-formatting.js'
import { configApi } from '../http-client.js'

export const deleteConfirmationPage = confirmationPageId => configApi.delete('/api/ConfirmationPages/' + confirmationPageId)

export const getConfirmationPages = () => configApi.get('/api/ConfirmationPages').then(({ data }) => ({
	confirmationPages: data.confirmationPages.map(confirmationPage => ({
		...confirmationPage,
		createdDate: format(new Date(confirmationPage.createdDate), DATE_FORMAT)
	})),
	assetsLinks: data.assetsLinks
}))

export const getConfirmationPageAssets = confirmationPageId => configApi.get(`/api/ConfirmationPages/GetAssetUrls/${confirmationPageId}`)
export const upsertConfirmationPage = confirmationPage => {
	return configApi.post(`/api/ConfirmationPages/Create`, {
		confirmationPageId: confirmationPage.confirmationPageId,
		brandId: confirmationPage.brandId,
		confirmationPageName: confirmationPage.confirmationPageName,
		confirmationPageHTML: confirmationPage.confirmationPageHtml,
		confirmationPageTranslations: confirmationPage.translations.map(translation => ({
			languageId: translation.languageId,
			translatedConfirmationPageHTML: translation.confirmationPageHtml
		}))
	}).then(x => x?.data)
}

export const uploadFiles = files => {
	return configApi.post(`/api/ConfirmationPages/UploadAssets`, files,
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
}
export const deleteConfirmationPageAsset = fileToDelete => {
	return configApi.delete('/api/ConfirmationPages/DeleteAsset',
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data: fileToDelete
		})
}
