<template>
	<ValidationForm
		#default="{ handleSubmit, invalid }"
		tag="div"
		class="d-flex flex-column overflow-y-hidden"
		style="width: 100%;"
	>
		<PreferenceWidgetsLayout>
			<template #header-after>
				<Stepper
					:key="currentStepKey"
					:step="currentStep"
					:steps="steps"
					:in-progress="!isEdit"
					@update:step="value => {
						handleSubmit(() => {
							currentStep = value
						})
						currentStepKey++
					}"
				/>
			</template>
			<template #content>
				<StepperItems
					:step="currentStep"
					:steps="steps"
				>
					<template #0>
						<PreferenceWidgetDetailsStep
							:preference-widget.sync="preferenceWidget"
							:lookup-data="preferenceWidgetsLookupData"
							:user-full-permissions="fullPermissionsAndBrandAccess"
							:edit-mode="isEdit"
							@navigate-forward="currentStep++"
							@created="postPreferenceWidget"
						/>
					</template>
					<template #1>
						<PreferenceWidgetPreferencesStep
							:preference-widget.sync="preferenceWidget"
							:lookup-data="preferenceWidgetsLookupData"
							:user-full-permissions="fullPermissionsAndBrandAccess"
							:edit-mode="isEdit"
							@navigate-back="currentStep--"
							@created="postPreferenceWidget"
						/>
					</template>
					<template #2>
						<PreferenceWidgetEmbedCodeStep
							:preference-widget="preferenceWidget"
							:option-appearance="preferenceWidget.optionAppearance"
							:brand-id="preferenceWidget.brandId"
							:enable-cassie-cloud-embed-code="enableCassieCloudEmbedCode"
							@navigate-back="currentStep--"
						/>
					</template>
				</StepperItems>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="navigateToOverview">
							Back to Overview
						</SecondaryActionButton>
						<v-spacer />
						<SecondaryActionButton
							v-if="currentStep !== 0"
							@click="currentStep--"
						>
							Previous
						</SecondaryActionButton>
						<PrimaryActionButton
							v-if="userFullPermissions && !isEdit && currentStep === 1"
							class="ml-2"
							@click="handleSubmit(postPreferenceWidget)"
						>
							Create
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="userFullPermissions && isEdit && currentStep !== 2"
							class="ml-2"
							@click="handleSubmit(postPreferenceWidget)"
						>
							Save Changes
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="currentStep === 0"
							:disabled="invalid"
							class="ml-2"
							@click="currentStep++"
						>
							Next
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</PreferenceWidgetsLayout>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import PreferenceWidgetsLayout from './preference-widgets-layout.vue'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import Stepper from '../../../../../shared/components/stepper.vue'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import StepperItems from '../../../../../shared/components/stepper-items.vue'
import PreferenceWidgetDetailsStep from './preference-widget-details-step.vue'
import PreferenceWidgetPreferencesStep from './preference-widget-preferences-step.vue'
import PreferenceWidgetEmbedCodeStep from './preference-widget-embed-code-step.vue'
import { createPreferenceWidget, updatePreferenceWidget, publishPreferenceWidget } from '../../../../../shared/utils/api/preference-widgets.js'
import { brandOptions, defaultBrandInSelect } from '../../../../../shared/state/brands.js'
import { PREFERENCE_WIDGETS, CONSENT_COLLECTION } from '../../../router/route-names.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { updatePrivacyPolicies } from '../../../../../shared/state/privacy-policies.js'
import { enableCassieCloudEmbedCode } from '../../../../../shared/state/config-keys.js'
export default {
	components: {
		PreferenceWidgetEmbedCodeStep,
		PreferenceWidgetDetailsStep,
		PreferenceWidgetPreferencesStep,
		StepperItems,
		ValidationForm,
		Stepper,
		PreferenceWidgetsLayout,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		preferenceWidgetToEdit: Object,
		initialStep: Number,
		userFullPermissions: Boolean,
		accessedViaHub: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			defaultBrandInSelect,
			showSnackbar,
			updatePrivacyPolicies,
			enableCassieCloudEmbedCode
		}
	},
	data () {
		return {
			preferenceWidget: JSON.parse(JSON.stringify(this.preferenceWidgetToEdit || {
				brandId: defaultBrandInSelect.value
			})),
			currentStepKey: 0,
			currentStep: this.initialStep || 0,
			steps: [
				{ slot: 0, title: 'Details', stepNumber: '01', stepComplete: false },
				{ slot: 1, title: 'Preferences', stepNumber: '02', stepComplete: false },
				{ slot: 2, title: 'Embed Code', stepNumber: '03', stepComplete: false }
			],
			isEdit: false
		}
	},
	computed: {
		...mapGetters('lookupData', ['preferenceWidgetsLookupData']),
		fullPermissionsAndBrandAccess () {
			const canAccessBrand = this.preferenceWidget?.brandId != null ? brandOptions.value.find(({ value }) => value === this.preferenceWidget.brandId) : true
			return Boolean(this.userFullPermissions && canAccessBrand)
		},
		preferenceWidgetStatementGroups () {
			return this.preferenceWidget.statementGroups || []
		}
	},
	created () {
		if (this.preferenceWidget.preferenceWidgetId) {
			this.isEdit = true
		}

		if (this.preferenceWidget.externalValues === undefined) {
			this.preferenceWidget.externalValues = []
		}

		updatePrivacyPolicies()
	},
	methods: {
		async postPreferenceWidget () {
			if (!this.preferenceWidgetStatementGroups.length) {
				showSnackbar({ text: useLabels('You must add at least one statement group'), color: 'red' })
			}
			if (!this.isEdit) {
				await createPreferenceWidget(this.preferenceWidget).then(response => {
					publishPreferenceWidget(response.data.newPreferenceWidgetId)
					this.preferenceWidget.preferenceWidgetId = response.data.newPreferenceWidgetId
				})
				this.isEdit = true
			} else {
				await updatePreferenceWidget(this.preferenceWidget).then(
					response => publishPreferenceWidget(this.preferenceWidget.preferenceWidgetId)
				)
			}
			if (this.preferenceWidget.extendedPreferences) {
				const prefWidgetWithExtendedPreferenceDates = this.preferenceWidget
				prefWidgetWithExtendedPreferenceDates.extendedPreferences = prefWidgetWithExtendedPreferenceDates.extendedPreferences.map(extPref => ({
					...extPref,
					dateCreated: extPref.dateCreated ? extPref.dateCreated : new Date()
				}))
				this.preferenceWidget.extendedPreferences = prefWidgetWithExtendedPreferenceDates.extendedPreferences
			}
			this.navigateToEmbedCode()
		},
		navigateForward () {
			this.steps[this.currentStep].stepComplete = true
			this.currentStep++
		},
		navigateToEmbedCode () {
			this.currentStep = 2
		},
		navigateToOverview () {
			if (this.accessedViaHub) {
				this.$router.push({ name: CONSENT_COLLECTION })
			} else {
				this.$router.push({ name: PREFERENCE_WIDGETS })
			}
		}
	}
}
</script>
