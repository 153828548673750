<template>
	<div>
		<SectionCard
			collapsible
			:collapsed="showExtendedPreferences"
		>
			<template #title>
				Extended Preferences
			</template>
			<template #body>
				<p>
					Manage Extended Preferences
				</p>
				<div
					class="cassie-vertical-md"
				>
					<div class="cassie-horizontal-sm d-flex flex-row">
						<Dropdown
							v-if="!isPreferenceWidget"
							v-model="selectedExtendedPreferenceChannel"
							return-object
							:label="'Channel *' | useLabels"
							class="cassie-input-width-md"
							:tooltip-text="LinksChannelTooltipText"
							:items="extendedPreferencesChannelItems"
							:disabled="!userFullPermissions"
						/>
						<Dropdown
							v-else
							v-model="selectedExtendedPreferenceStatementGroup"
							return-object
							:label="'Statement Group *' | useLabels"
							class="cassie-input-width-md"
							:items="extendedPreferencesStatementGroupItems"
							:disabled="!userFullPermissions"
						/>
						<PrimaryActionButton
							:disabled="!isPreferenceWidget ? !selectedExtendedPreferenceChannel : !selectedExtendedPreferenceStatementGroup || !userFullPermissions"
							@click="showAddExtendedPreferencesModal = true"
						>
							Add
						</PrimaryActionButton>
					</div>
					<DataTable
						:headers="extendedPreferencesTableHeaders"
						:items="extendedPreferencesDateFormatted"
						:disable-sort="false"
						:multi-sort="multiSort"
						:sort-by="sortBy"
						:sort-desc="sortDesc"
					>
						<template #item.extendedPreferenceName="{item}">
							{{ item.extendedPreferenceName ? item.extendedPreferenceName : displayExtendedPreferenceName(item.keyId) }}
						</template>
						<template #item.fieldTypeName="{item}">
							{{ item.fieldTypeName ? item.fieldTypeName : displayExtendedFieldTypeName(item.fieldTypeId) }}
						</template>
						<template #item.defaultValue="{item}">
							<span
								v-if="item.defaultFreeTextValue && item.fieldTypeId == 1"
								class="ml-2"
							>
								{{ item.defaultFreeTextValue }}
							</span>
							<span
								v-else-if="item.defaultSingleSelectValue && item.fieldTypeId == 2"
								class="ml-2"
							>
								{{ getDefaultSelectValue(item) }}
							</span>
							<IconButton
								v-else
								small-icon
								small
								align-center
							>
								mdi-close
							</IconButton>
						</template>
						<template #item.hideFromUi="{item}">
							<IconButton
								v-if="item.hideFromUi"
								small-icon
								small
							>
								mdi-check
							</IconButton>
							<IconButton
								v-else
								small-icon
								small
							>
								mdi-close
							</IconButton>
						</template>
						<template #item.isEditable="{item}">
							<IconButton
								v-if="item.isEditable"
								small-icon
								small
							>
								mdi-check
							</IconButton>
							<IconButton
								v-else
								small-icon
								small
							>
								mdi-close
							</IconButton>
						</template>
						<template #item.action="{ item }">
							<IconButton
								v-if="userFullPermissions"
								small-icon
								small
								@click="editExtendedPreference(item)"
							>
								mdi-pencil
							</IconButton>
							<IconButton
								v-if="userFullPermissions"
								small-icon
								small
								@click="removeExtendedPreference(item)"
							>
								mdi-close
							</IconButton>
						</template>
					</DataTable>
				</div>
			</template>
		</SectionCard>
		<ExtendedPreferencesModal
			v-if="showAddExtendedPreferencesModal || extendedPreferenceToEdit"
			:is-preference-widget="isPreferenceWidget"
			:statement-group="selectedExtendedPreferenceStatementGroup"
			:channel="selectedExtendedPreferenceChannel"
			:extended-preferences="extendedPreferences"
			:extended-preference-to-edit="extendedPreferenceToEdit"
			@update:extendedPreferences="updateExtendedPreferences"
			@extended-prefs-changed="$emit('extended-prefs-changed')"
			@close="() => {
				showAddExtendedPreferencesModal = false
				extendedPreferenceToEdit = null
				selectedExtendedPreferenceChannel = null
			}"
		/>
	</div>
</template>

<script>
import orderBy from 'lodash/orderBy'
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import DataTable from '../../../../shared/components/data-table.vue'
import SectionCard from '../../../../shared/components/section-card.vue'
import PrimaryActionButton from '../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../shared/components/dropdown.vue'
import ExtendedPreferencesModal from './extended-preferences-modal.vue'
import { getExtendedPreferences } from '../../../../shared/utils/api/extended-preferences.js'
import { LINKS_AREA_FULL_PERMISSIONS, PREF_WIDGETS_AREA_FULL_PERMISSIONS } from '../../../../shared/permissions/admin-portal-permissions.js'
import { DATE_FORMAT } from '../../../../shared/utils/date-formatting.js'
import { useLabels } from '../../../../shared/state/admin-portal-navigation.js'

export default {
	components: { DataTable, PrimaryActionButton, SectionCard, Dropdown, ExtendedPreferencesModal, IconButton },
	props: {
		preferences: Array,
		extendedPreferences: Array,
		parentType: String,
		isPreferenceWidget: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			showExtendedPreferences: false,
			selectedExtendedPreferenceChannel: null,
			selectedExtendedPreferenceStatementGroup: null,
			showAddExtendedPreferencesModal: false,
			extendedPreferenceToEdit: null,
			extendedPreferenceKeys: [],
			extendedPreferenceFieldTypes: [],
			multiSort: true,
			sortBy: ['statementGroupName', 'formattedDateCreated'],
			sortDesc: [false, false]
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			if (!this.isPreferenceWidget) return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS)
			else return this.productAreaPermission(PREF_WIDGETS_AREA_FULL_PERMISSIONS)
		},
		extendedPreferencesTableHeaders () {
			const tableHeaders = [
				{ text: 'Statement Group Name', value: 'statementGroupName', width: '15%' },
				{ text: useLabels('Channel'), value: 'channelName', width: '15%' },
				{ text: 'Extended Preference', value: 'extendedPreferenceName', width: '10%' },
				{ text: 'Field Type', value: 'fieldTypeName', width: '10%' },
				{ text: 'Default Value', value: 'defaultValue', width: '10%' },
				{ text: 'Hide from UI', value: 'hideFromUi', width: '10%' },
				{ text: 'Is Editable', value: 'isEditable', width: '10%' },
				{ text: 'Created Date', value: 'formattedDateCreated', width: '10%' },
				{ text: 'Action', value: 'action', align: 'right', width: '10%' }
			]

			return this.isPreferenceWidget ? tableHeaders : tableHeaders.filter(header => header.value !== 'statementGroupName')
		},
		extendedPreferencesChannelItems () {
			return this.preferences.map(preference => ({
				...preference,
				value: preference.channelId,
				text: preference.channelName
			}))
		},
		extendedPreferencesStatementGroupItems () {
			return this.preferences.map(preference => ({
				...preference,
				value: preference.statementGroupID,
				text: preference.statementGroupName
			}))
		},
		extendedPreferencesDateFormatted () {
			return orderBy(this.extendedPreferences.map(extendedPreference => ({
				...extendedPreference,
				formattedDateCreated: extendedPreference.dateCreated ? format(new Date(extendedPreference.dateCreated), DATE_FORMAT) : 'NEW: Pending Save'
			})), a => a.formattedDateCreated, ['desc'])
		},
		LinksChannelTooltipText () {
			return this.parentType === 'Link' ? 'Select the Channel to associate the Extended Preference with' : ''
		}
	},
	created () {
		this.getExtendedPreferenceKeys()
		if (this.extendedPreferences.length) {
			this.showExtendedPreferences = true
		}
	},
	methods: {
		displayExtendedPreferenceName (extendedPreferenceKeyId) {
			const matchingKey = this.extendedPreferenceKeys.find(({ extendedPreferenceId }) => extendedPreferenceId === extendedPreferenceKeyId)
			return matchingKey?.name
		},
		displayExtendedFieldTypeName (extendedPrefFieldTypeId) {
			return this.extendedPreferenceFieldTypes.find(({ fieldTypeId }) => fieldTypeId === extendedPrefFieldTypeId)?.fieldTypeName
		},
		getDefaultSelectValue (extendedPreference) {
			const matchingKey = this.extendedPreferenceKeys.find(({ extendedPreferenceId }) => extendedPreferenceId === extendedPreference.keyId)
			const matchingDropdownId = matchingKey?.dropdownValues?.find(({ valueId }) => valueId === extendedPreference.defaultSingleSelectValue)
			return matchingDropdownId?.value
		},
		removeExtendedPreference (item) {
			const index = this.extendedPreferences.findIndex(
				extendedPref => extendedPref.keyId === item.keyId && (
					!this.isPreferenceWidget
						? extendedPref.channelId === item.channelId
						: extendedPref.statementGroupId === item.statementGroupId))
			const extendedPreferences = this.extendedPreferences
			extendedPreferences.splice(index, 1)
			this.updateExtendedPreferences(extendedPreferences)
			this.$emit('extended-prefs-changed')
		},
		editExtendedPreference (extendedPreference) {
			this.extendedPreferenceToEdit = extendedPreference
			this.showAddExtendedPreferencesModal = true
		},
		updateExtendedPreferences (extendedPreferences) {
			this.$emit('update:extendedPreferences', extendedPreferences)
			this.orderExtendedPrefsTable()
		},
		async getExtendedPreferenceKeys () {
			const { extendedPreferences, lookupData } = await getExtendedPreferences()
			this.extendedPreferenceKeys = extendedPreferences
			this.extendedPreferenceFieldTypes = lookupData.extendedPreferenceFieldTypes
		},
		orderExtendedPrefsTable () {
			this.multiSort = true
			this.sortBy = ['statementGroupName', 'formattedDateCreated']
			this.sortDesc = [false, false]
		}
	}
}
</script>
