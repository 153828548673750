<template>
	<div>
		<v-tabs-items :value="step">
			<template v-for="{ slot } in steps">
				<v-tab-item
					:key="slot"
					:value="slot"
					transition="none"
				>
					<slot :name="slot" />
				</v-tab-item>
			</template>
		</v-tabs-items>
	</div>
</template>

<script>
export default {
	props: {
		step: {
			type: Number,
			required: true
		},
		steps: {
			type: Array,
			required: true
		}
	}
}
</script>
