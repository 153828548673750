<template>
	<SectionCard>
		<template #title>
			Embedded Consent Collection Submission Code
		</template>
		<template #title-action>
			<TextButton @click="copyCode(submissionCode)">
				Copy
			</TextButton>
		</template>
		<template #body>
			<p>
				1 - The submitWidget() function needs to be hooked in to your form submit event. Our recommended code is below. Please Note: if you elect to change this code, you are responsible for its implementation and support.
			</p>
			<Prism
				language="javascript"
				:code="submissionCode"
			/>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header>
						Variables
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<strong>
							FormID
						</strong> – The ID of this Embedded Consent Collection. Here we have pre-populated it with the correct ID for this Point
						<br>
						<strong>
							AccessKey
						</strong> – The Access Key to use for this widget. We have pre-populated this for you.
						<br>
						<strong>
							RecordObject.DivID
						</strong> – The ID of the blank div you created earlier. We have pre-populated this for you
						<br>
						<strong>
							RecordObject.Title
						</strong> – The value from your Title field (if specified)
						<br>
						<strong>
							RecordObject.Forename
						</strong> – The value from your Forename field (REQUIRED)
						<br>
						<strong>
							RecordObject.MiddleName
						</strong> – The value from your MiddleName field (if specified)
						<br>
						<strong>
							RecordObject.Surname
						</strong> – The value from your Surname field (REQUIRED)
						<br>
						<strong>
							RecordObject.Email1
						</strong> – The value from your Email1 field (REQUIRED)
						<br>
						<strong>
							RecordObject.Email2
						</strong> – The value from your Email2 field (if specified)
						<br>
						<strong>
							RecordObject.Telephone1
						</strong> – The value from your Telephone1 field (if specified)
						<br>
						<strong>
							RecordObject.Telephone2
						</strong> – The value from your Telephone2 field (if specified)
						<br>
						<strong>
							RecordObject.Mobile
						</strong> – The value from your Mobile field (if specified)
						<br>
						<strong>
							RecordObject.Address1
						</strong> – The value from your Address1 field (if specified)
						<br>
						<strong>
							RecordObject.Address2
						</strong> – The value from your Address2 field (if specified)
						<br>
						<strong>
							RecordObject.Address3
						</strong> – The value from your Address3 field (if specified)
						<br>
						<strong>
							RecordObject.Address4
						</strong> – The value from your Address4 field (if specified)
						<br>
						<strong>
							RecordObject.Address5
						</strong> – The value from your Address5 field (if specified)
						<br>
						<strong>
							RecordObject.Town
						</strong> – The value from your Town field (if specified)
						<br>
						<strong>
							RecordObject.County
						</strong> – The value from your County field (if specified)
						<br>
						<strong>
							RecordObject.Postcode
						</strong> – The value from your Postcode field (if specified)
						<br>
						<strong>
							RecordObject.Country
						</strong> – The value from your Country field (if specified)
						<br>
						<strong>
							RecordObject.CampaignCode
						</strong> – The value from your Campaign Code field (if specified)
						<br>
						<strong>
							RecordObject.SourceCode
						</strong> – The value from your Source Code field (if specified)
						<br>
						<strong>
							RecordObject.ResponseChannel
						</strong> – The value from your Response Channel field (if specified)
						<br>
						For Admin Widget Only:
						<br>
						<br>
						<strong>
							CSPortalUserIDforADMINWidget
						</strong> – The User ID for the special CS Portal user that has been set up to represent data captured by the widget embedded within the ADMIN system
						<br>
						<strong>
							ContactNumber
						</strong> – The URN3 unique reference value for the supporter whose preferences are being captured
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</template>
	</SectionCard>
</template>

<script>
import Prism from 'vue-prism-component'
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
export default {
	components: {
		SectionCard,
		TextButton,
		Prism
	},
	props: {
		submissionCode: String
	},
	methods: {
		copyCode (snippet) {
			navigator.clipboard.writeText(snippet)
		}
	}
}
</script>
