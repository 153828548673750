<template>
	<div
		class="cassie-vertical-md"
	>
		<PreferenceWidgetPreferencesCard
			:preference-widget="preferenceWidget"
			:update-preference-widget="updatePreferenceWidget"
			:channels="channels"
			:user-full-permissions="userFullPermissions"
		/>
		<ExtendedPreferencesCard
			is-preference-widget
			:preferences="preferenceWidgetStatementGroups"
			:extended-preferences="preferenceWidget.extendedPreferences || []"
			@update:extendedPreferences="value => updatePreferenceWidget('extendedPreferences', value)"
		/>
		<PreferenceWidgetPreviewCard
			v-if="preferenceWidgetStatementGroups.length"
			:preference-widget="preferenceWidget"
			:extended-preferences="preferenceWidget.extendedPreferences || []"
			:user-full-permissions="userFullPermissions"
			:channels="channels"
			:headers="headers"
			:footers="footers"
		/>
	</div>
</template>
<script>
import ExtendedPreferencesCard from '../../shared/extended-preferences-card.vue'
import { getPreferenceChannels } from '../../../../../shared/utils/api/channels.js'
import { getStatementGroups } from '../../../../../shared/utils/api/statement-groups.js'
import { getFooters, getHeaders } from '../../../../../shared/utils/api/headers-and-footers.js'
import { headersAndFootersTypeIdEnum } from '../../../../../shared/enums/headers-and-footers.js'
import PreferenceWidgetPreferencesCard from './preference-widget-preferences-card.vue'
import PreferenceWidgetPreviewCard from './preference-widget-preview-card.vue'

export default {
	components: {
		PreferenceWidgetPreviewCard,
		PreferenceWidgetPreferencesCard,
		ExtendedPreferencesCard
	},
	props: {
		preferenceWidget: {
			type: Object,
			default: () => ({})
		},
		lookupData: {
			type: Object,
			default: () => ({})
		},
		userFullPermissions: Boolean,
		editMode: Boolean
	},
	data () {
		return {
			channels: [],
			headers: [],
			footers: [],
			showExtendedPreferences: false,
			isEdit: false
		}
	},
	computed: {
		preferenceWidgetStatementGroups () {
			return this.preferenceWidget.statementGroups || []
		}
	},
	async created () {
		const [
			{ data: { channels } }
		] = await Promise.all([
			getPreferenceChannels(),
			getStatementGroups(),
			this.loadHeadersFooters()
		])
		this.channels = channels
	},
	methods: {
		async loadHeadersFooters () {
			const [
				{ data: headers },
				{ data: footers }
			] = await Promise.all([
				getHeaders(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS),
				getFooters(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS)
			])
			this.headers = headers ?? []
			this.footers = footers ?? []
		},
		updatePreferenceWidget (property, value) {
			this.$emit('update:preferenceWidget', {
				...this.preferenceWidget,
				[property]: value
			})
		}
	}
}
</script>
