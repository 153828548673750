<template>
	<SectionCard>
		<template #title>
			Preview
		</template>
		<template #title-action>
			<Dropdown
				v-if="showTranslations"
				v-model="selectedLanguageId"
				:items="availableLanguages"
				custom-sort
				:disabled="!userFullPermissions"
				label="Show available translations in"
			/>
		</template>
		<template #body>
			<div
				v-if="headerHtml"
				v-dompurify-html="headerHtml"
			/>
			<template v-if="[RADIO_APPEARANCE, RADIO_WITH_NO_CHANGE_APPEARANCE].includes(preferenceWidget.optionAppearance)">
				<PreferenceWidgetPreviewRadioOptions
					:preference-widget="preferenceWidget"
					:statements="statements"
				/>
			</template>
			<template v-else>
				<div
					v-for="(statement, statementIndex) in statements"
					:key="statementIndex"
				>
					<v-checkbox
						:label="statement.statementText"
						:value="statement.defaultValue"
						dense
						hide-details
					/>
					<div
						v-for="(extendedPreference, extendedPreferenceIndex) in statementExtendedPreferences(statement)"
						:key="extendedPreferenceIndex"
					>
						<component
							:is="extendedPreference.fieldTypeId === EXTENDED_PREFERENCES_FIELD_TYPES.FREE_TEXT_FIELD ? 'TextField' : 'Dropdown'"
							v-if="!extendedPreference.hideFromUi"
							:value="extendedPreference.fieldTypeId === EXTENDED_PREFERENCES_FIELD_TYPES.FREE_TEXT_FIELD ? extendedPreference.defaultFreeTextValue : extendedPreference.defaultSingleSelectValue"
							:label="extendedPreference.keyLabel"
							:items="extendedPreference.fieldTypeId !== EXTENDED_PREFERENCES_FIELD_TYPES.FREE_TEXT_FIELD ? getExtendedPreferenceDropdownOptions(extendedPreference.extendedPreferenceAvailableValues) : []"
							:multiple="extendedPreference.fieldTypeId === EXTENDED_PREFERENCES_FIELD_TYPES.MULTI_SELECT_DROPDOWN_FIELD"
							:disabled="!extendedPreference.isEditable"
							class="cassie-input-width-md ml-8 my-3"
						/>
					</div>
				</div>
			</template>
			<div
				v-if="footerHtml"
				v-dompurify-html="footerHtml"
			/>
		</template>
	</SectionCard>
</template>

<script>
import orderBy from 'lodash/orderBy'
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../../../shared/utils/date-formatting.js'
import SectionCard from '../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import { RADIO_APPEARANCE, RADIO_WITH_NO_CHANGE_APPEARANCE } from './widget-configuration.js'
import PreferenceWidgetPreviewRadioOptions from './preference-widget-preview-radio-options.vue'
import { getHeaderTranslations, getFooterTranslations } from '../../../../../shared/utils/api/headers-and-footers.js'
import { EXTENDED_PREFERENCES_FIELD_TYPES } from '../../../../../shared/enums/extended-preferences.js'
export default {
	components: { PreferenceWidgetPreviewRadioOptions, SectionCard, Dropdown, TextField },
	props: {
		preferenceWidget: Object,
		headers: Array,
		footers: Array,
		channels: Array,
		userFullPermissions: Boolean,
		extendedPreferences: Array
	},
	data () {
		return {
			EXTENDED_PREFERENCES_FIELD_TYPES,
			RADIO_APPEARANCE,
			RADIO_WITH_NO_CHANGE_APPEARANCE,
			selectedLanguageId: 0,
			headerTranslations: [],
			footerTranslations: []
		}
	},
	computed: {
		headerHtml () {
			if (!this.preferenceWidget.headerId) return ''
			if (this.selectedLanguageId === 0) return this.headers.find(({ id }) => id === this.preferenceWidget.headerId)?.html
			else {
				return this.headerTranslations.filter(({ id }) => id === this.preferenceWidget.headerId)
					.find(({ languageId }) => languageId === this.selectedLanguageId)?.translatedHtml ||
					this.headers.find(({ id }) => id === this.preferenceWidget.headerId)?.html
			}
		},
		footerHtml () {
			if (!this.preferenceWidget.footerId) return ''
			if (this.selectedLanguageId === 0) return this.footers.find(({ id }) => id === this.preferenceWidget.footerId)?.html
			else {
				return this.footerTranslations.filter(({ id }) => id === this.preferenceWidget.footerId)
					.find(({ languageId }) => languageId === this.selectedLanguageId)?.translatedHtml ||
					this.footers.find(({ id }) => id === this.preferenceWidget.footerId)?.html
			}
		},
		statements () {
			const allStatements = this.channels.map(channel => {
				return channel.statements
			}).flat()
			const prefWidgetStatements = this.preferenceWidget.statementGroups?.map(statementGroup => {
				return {
					...statementGroup.statements[0],
					statementGroupId: statementGroup.statementGroupID
				}
			})
			const statements = prefWidgetStatements.map(statement => ({
				...statement,
				statementText: allStatements.find(allStatement => allStatement.statementID === statement.statementID || allStatement.statementID === statement.statementId)
					?.liveStatements.find(({ languageId }) => languageId === this.selectedLanguageId)?.statementText || statement.statementText,
				liveStatements: allStatements.find(allStatement => allStatement.statementID === statement.statementID || allStatement.statementID === statement.statementId)?.liveStatements
			}))
			return statements
		},
		extendedPrefs () {
			return this.extendedPreferences
		},
		showTranslations () {
			return this.availableLanguages.length > 1 // More than just Default
		},
		availableLanguages () {
			let statementTranslations = this.statements?.map(statement => ({
				...statement
			}))
			statementTranslations = statementTranslations.filter(({ liveStatements }) => liveStatements?.length >= 1).map(statement => {
				return statement.liveStatements
			}).flat()
			const availableTranslationOptions = []
			statementTranslations.forEach(statementTranslation => {
				if (statementTranslation.languageId) {
					availableTranslationOptions.push({
						...statementTranslation,
						value: statementTranslation.languageId,
						text: statementTranslation.languageName
					})
				}
			})
			return [{ value: 0, text: 'Default' }, ...availableTranslationOptions.sort((a, b) =>
				a.text.localeCompare(b.text)
			)]
		}
	},
	created () {
		this.getHeaderAndFooterTranslations()
	},
	methods: {
		async getHeaderAndFooterTranslations () {
			const { headerTranslations } = await getHeaderTranslations()
			this.headerTranslations = headerTranslations.flat()
			const { footerTranslations } = await getFooterTranslations()
			this.footerTranslations = footerTranslations.flat()
		},
		statementExtendedPreferences (statement) {
			const extendedPrefs = this.extendedPrefs.filter(({ statementGroupId }) => statementGroupId === statement.statementGroupId).map(extendedPref => ({
				...extendedPref,
				dateCreated: extendedPref.dateCreated ? format(new Date(extendedPref.dateCreated), DATE_FORMAT) : new Date()
			}))
			return orderBy(extendedPrefs, a => a.dateCreated, ['desc'])
		},
		getExtendedPreferenceDropdownOptions (extendedPreferenceAvailableValues) {
			return extendedPreferenceAvailableValues.map(({ extendedPreferenceKeyAvailableValueId, extendedPreferenceKeyAvailableValue }) => ({
				value: extendedPreferenceKeyAvailableValueId,
				text: extendedPreferenceKeyAvailableValue
			}))
		}
	}
}
</script>
