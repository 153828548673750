<template>
	<div
		class="cassie-vertical-md"
	>
		<PreferenceWidgetDetailsCard
			:preference-widget="preferenceWidget"
			:update-preference-widget="updatePreferenceWidget"
			:headers="headers"
			:footers="footers"
			:user-full-permissions="userFullPermissions"
			:edit-mode="editMode"
			@load-headers-footers="loadHeadersFooters"
		/>
		<PreferenceWidgetOtherConfigCard
			:preference-widget="preferenceWidget"
			:lookup-data="lookupData"
			:update-preference-widget="updatePreferenceWidget"
			:edit-mode="editMode"
			:user-full-permissions="userFullPermissions"
		/>
		<PrivacyPolicyCard
			:preference-widget="preferenceWidget"
			:update-preference-widget="updatePreferenceWidget"
			:edit-mode="editMode"
			:user-full-permissions="userFullPermissions"
		/>
		<MetadataCard
			module-type="Embedded Consent Collection"
			:component-type-id="COMPONENT_TYPE.PREFERENCE_WIDGETS"
			:assigned-meta-data="preferenceWidget.externalValues"
			:read-only="!userFullPermissions"
			@update:metadata="updateMetadata($event)"
		/>
	</div>
</template>

<script>
import MetadataCard from '../../shared/metadata-card.vue'
import { PREFERENCE_WIDGETS } from '../../../router/route-names.js'
import PreferenceWidgetOtherConfigCard from './preference-widget-other-config-card.vue'
import PreferenceWidgetDetailsCard from './preference-widget-details-card.vue'
import { getFooters, getHeaders } from '../../../../../shared/utils/api/headers-and-footers.js'
import { headersAndFootersTypeIdEnum } from '../../../../../shared/enums/headers-and-footers.js'
import { COMPONENT_TYPE } from '../../../../../shared/state/meta-data.js'
import PrivacyPolicyCard from './preference-widget-privacy-policy-card.vue'
export default {
	components: {
		PreferenceWidgetDetailsCard,
		PreferenceWidgetOtherConfigCard,
		MetadataCard,
		PrivacyPolicyCard
	},
	props: {
		preferenceWidget: {
			type: Object,
			default: () => ({})
		},
		lookupData: {
			type: Object,
			default: () => ({})
		},
		userFullPermissions: Boolean,
		editMode: Boolean
	},
	setup () {
		return {
			COMPONENT_TYPE
		}
	},
	data () {
		return {
			headers: [],
			footers: [],
			isEdit: false
		}
	},
	async created () {
		this.loadHeadersFooters()
	},
	methods: {
		async loadHeadersFooters () {
			const [
				{ data: headers },
				{ data: footers }
			] = await Promise.all([
				getHeaders(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS),
				getFooters(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS)
			])
			this.headers = headers ?? []
			this.footers = footers ?? []
		},
		navigateToOverview () {
			this.$router.push({ name: PREFERENCE_WIDGETS })
		},
		updateMetadata (assignedMetadata) {
			this.updatePreferenceWidget('externalValues', assignedMetadata)
		},
		updatePreferenceWidget (property, value) {
			this.$emit('update:preferenceWidget', {
				...this.preferenceWidget,
				[property]: value
			})
		}
	}
}
</script>
