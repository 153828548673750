<template>
	<v-tabs
		:value="step"
		:height="70"
		hide-slider
		grow
		class="rounded-lg cassie-stepper"
		@change="$emit('update:step', $event)"
	>
		<v-tab
			v-for="{ title, slot, stepNumber, stepComplete } in steps"
			:key="slot"
			:disabled="inProgress"
			link
			active-class="rounded-lg blue darken-2 white--text cassie-active-step-opacity"
			class="text-capitalize cassie-step"
			:class="{
				'cassie-step-complete white--text': stepComplete && inProgress
			}"
		>
			<span class="text-h6 font-weight-bold mr-2">
				{{ stepNumber }}</span>
			{{ title }}
		</v-tab>
	</v-tabs>
</template>

<script>
export default {
	props: {
		step: {
			type: Number,
			required: true
		},
		steps: {
			type: Array,
			required: true
		},
		inProgress: {
			type: Boolean,
			default: true
		}
	}
}
</script>

<style lang="scss">
.cassie-stepper {
	box-shadow: var(--global-cassie-tabs-container-box-shadow);

}

.cassie-step {
	border-right: var(--global-cassie-tab-border-right);
	&:nth-last-child(1) {
		border-right: none;
	}
}

.cassie-step-complete.cassie-step-complete {
	font-size: var(--cassie-tab-font-size);
	text-transform: var(--cassie-tab-text-transform);
	background-color: var(--cassie-tab-step-complete-background-color);
	border-right: var(--cassie-tab-border-right);
	border-radius: var(--cassie-tab-active-border-radius);
	opacity: var(--cassie-tab-opacity);
	&:nth-last-child(1) {
		border-right: none;
	}
}

.cassie-active-step-opacity {
	opacity: 1 !important;
}
</style>
