<template>
	<div class="cassie-vertical-md">
		<template v-for="(statement, index) in statements">
			<div :key="index+ 'label'">
				{{ statement.statementText }}
			</div>
			<v-radio-group
				v-if="preferenceWidget.optionOrder === OPTION_ORDER_IN_NO_CHANGE_OUT"
				:key="index + 'radio-group'"
				:value="preferenceWidget.defaultOption || OPTION_OPT_OUT"
				row
				dense
				hide-details
			>
				<Radio
					:label="preferenceWidget.optInLabel || 'Opt In'"
					:value="OPTION_OPT_IN"
					:reverse-label="preferenceWidget.labelLocation === LABEL_LOCATION_LEFT_OF_FIELD"
				/>
				<Radio
					v-if="preferenceWidget.optionAppearance === RADIO_WITH_NO_CHANGE_APPEARANCE"
					:label="preferenceWidget.noChangeLabel|| 'No Change'"
					:value="OPTION_NO_CHANGE"
					:reverse-label="preferenceWidget.labelLocation === LABEL_LOCATION_LEFT_OF_FIELD"
				/>
				<Radio
					:label="preferenceWidget.optOutLabel || 'Opt Out'"
					:value="OPTION_OPT_OUT"
					:reverse-label="preferenceWidget.labelLocation === LABEL_LOCATION_LEFT_OF_FIELD"
				/>
			</v-radio-group>
			<v-radio-group
				v-else
				:key="index + 'radio-group-reverse'"
				:value="preferenceWidget.defaultOption || OPTION_OPT_OUT"
				row
				dense
				hide-details
			>
				<Radio
					:label="preferenceWidget.optOutLabel || 'Opt Out'"
					:value="OPTION_OPT_OUT"
					:reverse-label="preferenceWidget.labelLocation === LABEL_LOCATION_LEFT_OF_FIELD"
				/>
				<Radio
					v-if="preferenceWidget.optionAppearance === RADIO_WITH_NO_CHANGE_APPEARANCE"
					:label="preferenceWidget.noChangeLabel|| 'No Change'"
					:value="OPTION_NO_CHANGE"
					:reverse-label="preferenceWidget.labelLocation === LABEL_LOCATION_LEFT_OF_FIELD"
				/>
				<Radio
					:label="preferenceWidget.optInLabel || 'Opt In'"
					:value="OPTION_OPT_IN"
					:reverse-label="preferenceWidget.labelLocation === LABEL_LOCATION_LEFT_OF_FIELD"
				/>
			</v-radio-group>
			<div
				v-for="extendedPreference in statementExtendedPreferences(statement)"
				:key="extendedPreference.keyId"
			>
				<component
					:is="extendedPreference.fieldTypeId === EXTENDED_PREFERENCES_FIELD_TYPES.FREE_TEXT_FIELD ? 'TextField' : 'Dropdown'"
					v-if="!extendedPreference.hideFromUi"
					:value="extendedPreference.fieldTypeId === EXTENDED_PREFERENCES_FIELD_TYPES.FREE_TEXT_FIELD ? extendedPreference.defaultFreeTextValue : extendedPreference.defaultSingleSelectValue"
					:label="extendedPreference.keyLabel"
					:items="extendedPreference.fieldTypeId !== EXTENDED_PREFERENCES_FIELD_TYPES.FREE_TEXT_FIELD ? getExtendedPreferenceDropdownOptions(extendedPreference.extendedPreferenceAvailableValues) : []"
					:multiple="extendedPreference.fieldTypeId === EXTENDED_PREFERENCES_FIELD_TYPES.MULTI_SELECT_DROPDOWN_FIELD"
					:disabled="!extendedPreference.isEditable"
					class="cassie-input-width-md ml-8 my-3"
				/>
			</div>
		</template>
	</div>
</template>

<script>
import orderBy from 'lodash/orderBy'
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../../../shared/utils/date-formatting.js'
import {
	LABEL_LOCATION_LEFT_OF_FIELD,
	OPTION_NO_CHANGE,
	OPTION_OPT_IN,
	OPTION_OPT_OUT,
	OPTION_ORDER_IN_NO_CHANGE_OUT,
	OPTION_ORDER_IN_OUT, RADIO_WITH_NO_CHANGE_APPEARANCE
} from './widget-configuration.js'
import Radio from '../../../../../shared/components/radio.vue'
import { EXTENDED_PREFERENCES_FIELD_TYPES } from '../../../../../shared/enums/extended-preferences.js'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'

export default {
	components: { Dropdown, TextField, Radio },
	props: {
		preferenceWidget: Object,
		statements: Array
	},
	data () {
		return {
			OPTION_OPT_IN,
			OPTION_OPT_OUT,
			OPTION_NO_CHANGE,
			OPTION_ORDER_IN_NO_CHANGE_OUT,
			OPTION_ORDER_IN_OUT,
			RADIO_WITH_NO_CHANGE_APPEARANCE,
			LABEL_LOCATION_LEFT_OF_FIELD,
			EXTENDED_PREFERENCES_FIELD_TYPES
		}
	},
	methods: {
		statementExtendedPreferences (statement) {
			const extendedPrefs = this.preferenceWidget.extendedPreferences?.filter(({ statementGroupId }) => statementGroupId === statement.statementGroupId).map(extendedPref => ({
				...extendedPref,
				dateCreated: extendedPref.dateCreated ? format(new Date(extendedPref.dateCreated), DATE_FORMAT) : new Date()
			}))
			return orderBy(extendedPrefs, a => a.dateCreated, ['desc'])
		},
		getExtendedPreferenceDropdownOptions (extendedPreferenceAvailableValues) {
			return extendedPreferenceAvailableValues.map(({ extendedPreferenceKeyAvailableValueId, extendedPreferenceKeyAvailableValue }) => ({
				value: extendedPreferenceKeyAvailableValueId,
				text: extendedPreferenceKeyAvailableValue
			}))
		}
	}
}
</script>
