var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{staticClass:"rounded-lg cassie-stepper",attrs:{"value":_vm.step,"height":70,"hide-slider":"","grow":""},on:{"change":function($event){return _vm.$emit('update:step', $event)}}},_vm._l((_vm.steps),function(ref){
var title = ref.title;
var slot = ref.slot;
var stepNumber = ref.stepNumber;
var stepComplete = ref.stepComplete;
return _c('v-tab',{key:slot,staticClass:"text-capitalize cassie-step",class:{
			'cassie-step-complete white--text': stepComplete && _vm.inProgress
		},attrs:{"disabled":_vm.inProgress,"link":"","active-class":"rounded-lg blue darken-2 white--text cassie-active-step-opacity"}},[_c('span',{staticClass:"text-h6 font-weight-bold mr-2"},[_vm._v(" "+_vm._s(stepNumber))]),_vm._v(" "+_vm._s(title)+" ")])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }