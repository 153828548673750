export const CHECKBOX_APPEARANCE = 1
export const RADIO_APPEARANCE = 2
export const RADIO_WITH_NO_CHANGE_APPEARANCE = 3

export const OPTION_OPT_OUT = 0
export const OPTION_OPT_IN = 1
export const OPTION_NO_CHANGE = 3

export const LABEL_LOCATION_LEFT_OF_FIELD = false
const LABEL_LOCATION_RIGHT_OF_FIELD = true

export const OPTION_ORDER_OUT_NO_CHANGE_IN = false
export const OPTION_ORDER_IN_NO_CHANGE_OUT = true
export const OPTION_ORDER_OUT_IN = 3
export const OPTION_ORDER_IN_OUT = 4

export const optionOrder = [
	{ text: 'Opt Out | Opt In', value: OPTION_ORDER_OUT_IN },
	{ text: 'Opt In | Opt Out', value: OPTION_ORDER_IN_OUT }
]

export const optionOrderWithNoChange = [
	{ text: 'Opt Out | No Change | Opt In', value: OPTION_ORDER_OUT_NO_CHANGE_IN },
	{ text: 'Opt In | No Change | Opt Out ', value: OPTION_ORDER_IN_NO_CHANGE_OUT }
]

export const labelLocationOptions = [
	{ text: 'Left of Input', value: LABEL_LOCATION_LEFT_OF_FIELD },
	{ text: 'Right of Input', value: LABEL_LOCATION_RIGHT_OF_FIELD }
]

export const defaultOptions = [
	{ text: 'Opt Out', value: OPTION_OPT_OUT },
	{ text: 'Opt In', value: OPTION_OPT_IN },
	{ text: 'No Change', value: OPTION_NO_CHANGE }
]
